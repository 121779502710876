export default {
  computed: {
    list() {
      return this.$store.state.moduleClouds.list;
    },
    isLoading() {
      return this.$store.state.moduleClouds.isLoading;
    },
  },
  methods: {
    fetch() {
      return this.$store.dispatch('moduleClouds/fetchList');
    },
  },
};
